<template>
   <div class="page-wrapper-header d-flex justify-content-between">
    <div class="page-wrapper-title">
      <p class="text-color-65-gray text-fteen mb-0">
        <span class="text-nowrap"><strong>{{titleItems[0].count}}</strong> {{titleItems[0].title}},</span> <br />
        <span class="text-nowrap"><strong>{{titleItems[1].count}}</strong> {{titleItems[1].title}}</span>
      </p>
    </div>
    <div class="d-flex">
      <div class="d-flex align-items-center ms-4 me-4 cursor-pointer">
        <div class="d-flex align-items-center" @mouseleave="onLeave" @mouseover="icoType='fill'" @click="onFav">
          <span class="me-1">
            <ph-star :size="28" :weight="icoType" color="#ee7f00" />
          </span>
          <span class="me-2 text-color-65-gray">Favoriten</span>
        </div>
        <div class="form-group" style="width: 150px;">
          <multi-select
            placeholder="Persönliche"
            :searchable="false"
            :showLabels="false"
            open-direction="bottom"
            v-model="favType"
            :options="options"
            :hide-selected="true"
            @select="changeFavType"
          >
            <template slot="singleLabel" slot-scope="props"
              ><span class="selected-option"> {{ props.option.val }} </span></template
            >
            <template slot="option" slot-scope="props">
              <div class="option-list-item">
                <span>{{ props.option.val }}</span>
              </div>
            </template>
          </multi-select>
        </div>
      </div>
      <div class="page-sorting-items" v-if="!tableView">
        <span class="me-3 text-color-65-gray"
          >Sortierung</span
        >
        <select
          @change="changeSort"
          v-model="sortType"
          class="form-control sorting-select"
        >
          <option selected value="AZ">A-Z</option>
          <option value="ZA">Z-A</option>
          <option v-if="isSupplier" value="09">0-9</option>
          <option v-if="isSupplier" value="90">9-0</option>
        </select>
      </div>
      <div class="d-flex align-items-center ms-4">
        <span class="text-color-65-gray me-3">Ansicht</span>
        <div class="page-list-view-change">
          <div
            @click="changeListingType('box')"
            class="item box"
            :class="{ active: listType === 'box' && !tableView }"
          >
            <v-icon :icon="['fac', 'box']" />
          </div>
          <div
            class="item box"
            @click="changeListingType('list')"
            :class="{ active: listType === 'list' && !tableView }"
          >
            <ph-list :size="20" weight="bold" />
          </div>
          <div
            v-if="modelView"
            class="item box ms-2"
            @click="$emit('changeTableView')"
            :class="{ active: tableView }"
          >
            <ph-table :size="20" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhList, PhStar, PhTable } from 'phosphor-vue'
import MultiSelect from 'vue-multiselect'
export default {
  props: {
    isSupplier: {
      type: Boolean,
      default: false
    },
    fav: {
      type: Boolean,
      default: false
    },
    tableView: {
      type: Number,
      default: 0
    },
    titleItems: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: String
    },
    listType: {
      type: String
    }
  },
  components: { PhList, PhStar, PhTable, MultiSelect },
  data () {
    return {
      sortType: this.sorting,
      isFav: this.fav,
      favType: {},
      icoType: 'thin',
      options: [
        { key: 'private', val: 'Persönliche' },
        { key: 'public', val: 'Unternehmen' }
      ]
    }
  },
  computed: {
    modelView () {
      return this.$route.name === 'sellerModels' || this.$route.name === 'buyerModels'
    }
  },
  methods: {
    changeListingType (par) {
      this.$emit('changeListingType', par)
    },
    changeSort () {
      this.$emit('sorting', this.sortType)
    },
    onLeave () {
      if (!this.isFav) { this.icoType = 'thin' }
    },
    onFav () {
      this.isFav = !this.isFav
      this.icoType = this.isFav ? 'fill' : 'thin'
      this.$emit('fav', this.isFav)
    },
    changeFavType (e) {
      this.$emit('favType', e.key)
    }
  },
  mounted () {
    this.favType = this.options[this.$store.getters.getFavoriteType === 'private' ? 0 : 1]
    this.icoType = this.fav ? 'fill' : 'thin'
  }
}
</script>
