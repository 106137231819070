export function decisionMerchant (data) {
  switch (+data.errorCode) {
    case 0: return 'M1'
    case 2400: return 'M2'
    case 2401: return 'M3'
    default: return 'M4'
  }
}

export function decisionWarehouseStock (data) {
  if (!data) return 'WS1'
  if (!data.stockable) return 'WS2'
  if (data.amount > 0) return 'WS5'
  if (data.reavailability) return 'WS3'
  else return 'WS4'
}

export function decisionDeliveryWeek (data) {
  const isValidityDate = (new Date(data.validity) !== 'Invalid Date') && !isNaN(new Date(data.validity))
  if (!isValidityDate) {
    if (data.shippingTimeInWeeks) {
      if (data.bindingDeliveryTime) {
        return 'DT2'
      } else {
        return 'DT3'
      }
    } else {
      return 'DT1'
    }
  } else {
    if (data.stockable) {
      if (data.shippingTimeInWeeks) return 'DT6'
      else return 'DT7'
    } else {
      if (data.shippingTimeInWeeks) return 'DT4'
      else return 'DT5'
    }
  }
}
