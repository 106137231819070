<template>
  <div
    class="groups-item-childs-child cursor-pointer"
    :class="listingType === 'box' ? 'box' : 'list'"
    @mouseleave="showFav = false"
    @mouseover="showFav = true"
    @click="onCardClick"
  >
    <div
      class="groups-item-childs-child-header d-flex align-items-start"
      :class="{
        'justify-content-between': listingType === 'box',
        search: type === 'supplier-search'
      }"
    >
      <div class="left" :class="listingType === 'list' ? 'me-2' : 'me-0'">
        <h3 class="text-color-primary text-xs">{{ item.supplierId }}</h3>
        <h3 class="text-color-gray text-md text-uppercase">
          {{ item.fullName }}
        </h3>
      </div>
      <div class="me-2">
        <star-component
          v-if="listingType === 'box' && (showFav || isFav)"
          ref="star"
          :isFavorite="isFav"
          @onFav="onFav"
          :listingType="listingType"
        />
      </div>
    </div>
    <div
      class="box-footer d-flex"
      :class="listingType === 'box' ? 'box-footer' : 'list-footer'"
    >
      <div class="left w-100 d-flex">
        <div
          class="item d-flex flex-column"
          :class="listingType === 'box' ? 'me-3' : 'me-5'"
        >
          <span class="count text-sm text-color-65-gray font-bold">
            {{ item.totalActiveModel }}
          </span>
          <span class="text text-xs text-color-65-gray">Modelle</span>
        </div>
        <div class="item d-flex flex-column">
          <span class="count text-sm text-color-65-gray font-bold">
            {{ item.totalValidCatalogue }}
          </span>
          <span class="text text-xs text-color-65-gray">Kataloge</span>
        </div>
        <star-component
          v-if="listingType === 'list' && (showFav || isFav)"
          ref="star"
          :isFavorite="isFav"
          @onFav="onFav"
          :listingType="listingType"
          class="ms-auto pe-2"
        />
        <div
          class="d-flex justify-content-center align-items-center gap-2"
          :class="
            listingType !== 'list' || !(showFav || isFav) ? 'ms-auto' : ''
          "
        >
          <div
            v-if="isSPCZUser"
            class="item cursor-pointer d-flex flex-column table-button"
            @click.stop="onFilesClick"
          >
            <ph-file-text weight="bold" :size="18" />
          </div>
          <div class="item cursor-pointer d-flex flex-column table-button">
            <ph-caret-right weight="bold" :size="18" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import starComponent from '../common/starComponent.vue'
import { PhCaretRight, PhFileText } from 'phosphor-vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    listingType: {
      required: true,
      type: String
    },
    type: {
      type: String
    },
    item: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showFav: false,
      isFav: this.item.isFavorite,
      isSPCZUser: false
    }
  },
  components: {
    PhCaretRight,
    PhFileText,
    starComponent
  },
  computed: {
    ...mapGetters(['getUserOneRole']),
    favType() {
      return this.$store.getters.getFavoriteType
    }
  },
  methods: {
    onCardClick() {
      this.$emit('onCardClick', this.item)
    },
    onFilesClick() {
      this.$emit('onFilesClick', this.item)
    },
    async onFav(e, post) {
      try {
        let url = ''
        if (e === this.favType) {
          this.isFav = post
          this.showFav = post
          this.$refs.star.icoType = post ? 'fill' : 'thin'
          url = post ? '' : '/delete'
        }
        await this.$api.setFavorite(url, {
          type: 'supplier',
          access: e,
          supplierId: this.item.supplierId
        })
      } catch (e) {
        console.error(e)
      }
    }
  },
  created() {
    const isSPCZ = this.getUserOneRole('isSPCZUser')

    if (isSPCZ !== -1) {
      this.isSPCZUser = true
    }
  }
}
</script>
