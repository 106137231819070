<template>
  <div class="content" :class="{ 'overflow-hidden': loading }">
    <div v-if="loading" class="container-fluid">
      <div class="row h-100">
        <div class="col-12 h-100">
          <div
            class="page-wrapper justify-content-center align-items-center h-100"
          >
            <loading />
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar @ps-scroll-y="onScroll" class="w-100" ref="ps" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12" style="height: 80px">
            <page-title />
          </div>
          <div class="col-12">
            <div class="page-wrapper">
              <page-wrapper-header
                :sorting="sorting"
                :isSupplier="true"
                :titleItems="title"
                :listType="listingType"
                :fav="isFav"
                @favType="changeFavType"
                @fav="changeFav"
                @sorting="changeSort"
                @changeListingType="changeListingType"
              />
              <div class="page-wrapper-body">
                <BuyerOverviewList
                  :listingType="listingType"
                  :getList="list"
                  @onCardClick="onCardClick"
                  @onFilesClick="onFilesClick"
                />
                <div v-if="notMoreData && !loadingMore" class="row">
                  <div class="col-12">
                    <h3
                      class="justify-content-center d-flex w-100 mb-5 text-Keine"
                    >
                      Keine weiteren Daten
                    </h3>
                  </div>
                </div>
                <div v-if="loadingMore" class="row">
                  <div class="col-12">
                    <loading :size="'sm'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <sidebar-filter :path="path" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

import BuyerOverviewList from '@/components/buyer/OverviewList'
import Loading from '../components/common/Loading.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import SidebarFilter from '../components/common/SidebarFilter.vue'
import PageWrapperHeader from '../components/common/PageWrapperHeader.vue'
export default {
  components: {
    PageTitle,
    BuyerOverviewList,
    PageWrapperHeader,
    Loading,
    SidebarFilter
  },
  name: 'SellerList',
  data() {
    return {
      page: 1,
      list: [],
      title: [],
      filter: { modelStatus: 'active' },
      sort: 'asc',
      path: 'buyer',
      paginator: {},
      sorting: 'AZ',
      loading: true,
      notMoreData: false,
      loadingMore: false,
      listingType: 'box',
      type: 'supplierName',
      isFav: false
    }
  },
  computed: {
    ...mapGetters(['getUserOneRole', 'getListingType', 'getFavorite']),
    getList() {
      return this.list
    },
    suppCurrPos() {
      return this.$store.state.currPgState.supplier
    }
  },
  methods: {
    ...mapMutations([
      'SET_BREADCRUMB',
      'SET_LISTING_TYPE',
      'SET_FAVORITE',
      'SET_FAVORITE_TYPE',
      'SET_CURRENT_PAGE_STATE',
      'SET_SUPPLIER_CURRENT_STATE'
    ]),
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 2 >= scrollHeight) {
        this.moreData()
      }
    },
    moreData() {
      if (this.page === this.paginator.totalPages) {
        this.notMoreData = true
        return
      }
      if (!this.loadingMore) {
        this.page++
        this.getSellerList(true)
      }
    },
    onCardClick(payload) {
      this.$store.state.prevPg = this.$route.name
      this.SET_CURRENT_PAGE_STATE({
        type: 'supplier',
        pos: this.$refs.ps.$el.scrollTop
      })
      this.SET_SUPPLIER_CURRENT_STATE([this.list, this.title, this.sorting])
      this.$store.commit('SET_CURRENT_PAGE_STATE', { type: 'model', pos: 0 })
      const query = { seller: payload.fullName, id: payload.supplierId }
      if (this.$route.query.modelStatus) {
        query.modelStatus = this.$route.query.modelStatus
      }
      this.$router
        .push({
          path: '/buyer/models',
          query
        })
        .catch(() => {})
    },

    onFilesClick(payload) {
      this.$store.state.prevPg = this.$route.name
      this.SET_CURRENT_PAGE_STATE({
        type: 'supplier',
        pos: this.$refs.ps.$el.scrollTop
      })
      this.SET_SUPPLIER_CURRENT_STATE([this.list, this.title, this.sorting])
      this.SET_CURRENT_PAGE_STATE({
        type: 'model',
        pos: 0
      })
      const query = { seller: payload.fullName, id: payload.supplierId }
      if (this.$route.query.modelStatus) {
        query.modelStatus = this.$route.query.modelStatus
      }
      this.$router
        .push({
          path: '/buyer/files',
          query
        })
        .catch(() => {})
    },

    changeFavType(type) {
      this.SET_FAVORITE_TYPE(type)
      this.loading = true
      this.getSellerList()
    },
    changeListingType(par) {
      this.SET_LISTING_TYPE(par === 'box' ? 0 : 1)
      this.listingType = par
    },
    changeSort(par) {
      if (par === 'AZ') {
        this.sort = 'asc'
      }
      if (par === 'ZA') {
        this.sort = 'desc'
      }
      this.sorting = par
      this.loading = true
      this.page = 1
      if (par === 'AZ' || par === 'ZA') {
        this.type = 'supplierName'
        this.getSellerList()
      }
      if (par === '09') {
        this.sort = 'asc'
        this.type = 'supplierId'
        this.getSellerList()
      }
      if (par === '90') {
        this.sort = 'desc'
        this.type = 'supplierId'
        this.getSellerList()
      }
    },
    async changeFav(show) {
      this.isFav = show
      this.SET_FAVORITE({ route: 'buyer', type: 'supplier', val: show })
      this.page = 1
      this.getSellerList()
    },
    async getSellerList(more = false, supplierId = '') {
      try {
        if (more) {
          this.loadingMore = true
        }
        if (!more) {
          this.list = []
        }

        let query = {
          page: this.page,
          sortType: this.sort,
          sortField: this.type
        }
        if (this.$route.query.modelStatus) {
          this.filter.modelStatus = this.$route.query.modelStatus
        }
        if (this.filter) {
          this.filter = {
            ...this.filter,
            access: this.$store.getters.getFavoriteType
          }
          query = { ...query, filter: JSON.stringify(this.filter) }
        }
        const response = this.isFav
          ? await this.$api.getFavorites('suppliers', query)
          : await this.$api.getPurchaserList(query)
        this.paginator = response.data.data.paginator
        const items = response.data.data.items
        this.title = [
          {
            title: 'Lieferanten',
            count: this.isFav
              ? items.length
              : response.data.data.statistics.totalSupplierCount
          },
          {
            title: this.isFav ? '' : 'Kataloge',
            count: this.isFav
              ? ''
              : response.data.data.statistics.totalCatalogueCount
          }
        ]
        items.map(item => {
          const group = item.fullName.toUpperCase().charAt(0)
          const findIndex = this.list.findIndex(item => item.group === group)

          if (findIndex === -1) {
            this.list.push({
              group,
              items: []
            })
          }
          const findIndexIshave = this.list.findIndex(
            item => item.group === group
          )
          if (findIndexIshave !== -1) {
            this.list[findIndexIshave].items.push({ ...item })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        if (more) {
          this.loadingMore = false
        }
        this.loading = false
      }
    }
  },
  async created() {
    if (this.$route.query.supplierId) {
      this.filter = { supplierId: this.$route.query.supplierId }
    }
    const isBuyer = this.getUserOneRole('isPurchaserUser')
    if (isBuyer === -1) {
      return this.$router.push('/access-denied')
    }
    const data = [
      {
        title: 'LIeferant'
      },
      {
        title: ' / Einkauf'
      }
    ]
    this.SET_BREADCRUMB(data)
    this.listingType = this.getListingType ? 'list' : 'box'
    this.isFav = this.getFavorite?.buyer?.supplier
    if (this.suppCurrPos) {
      this.loading = false
      const state = Object.values(this.$store.state.supplierCurrState)
      this.list = state[0]
      this.title = state[1]
      this.sorting = state[2]
      return
    }
    await this.getSellerList()
  },
  mounted() {
    if (this.suppCurrPos) this.$refs.ps.$el.scrollTop = this.suppCurrPos
  }
}
</script>
