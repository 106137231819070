<template>
  <div class="card card-white h-100">
    <div class="card-body">
      <div v-if="stockLoading" class="d-flex h-100 justify-content-center">
        <loading :size="'sm'"  />
      </div>
      <div class="stock d-flex flex-column" v-if="data.length === 1">
        <div class="position-relative">
          <div  class="d-flex justify-content-between align-items-center">
            <div >
              <h5 class="title">MEIN HAUS</h5>
              <div class="light">
                (Status: {{ germanStamp(data[0].lastCheck) || '-' }})
              </div>
            </div>
          </div>
          <StockItem class="line" :icon="['M2','M4'].includes(whichCaseM) ? 'barrier' : 'none'" :dot="totalAmount ? 'green' : whichCaseM === 'M3' ? 'grey' : 'none'">
            <div v-if="totalAmount" slot="quantity" class="flexbox" style="align-items: baseline;">
              <div class="bold semi-title">{{ totalAmount }}</div>
            </div>
            <div class="text-xs">{{ explanationM }}</div>
          </StockItem>
        </div>
        <div class="position-relative">
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div>
              <h5 class="title">Verfügbarkeit Lieferant</h5>
              <div class="light mb-2"> (Status: {{ hasField(data[0], 'warehouseStock.result.lastCheck') ? data[0].warehouseStock.result.lastCheck : '-' }})</div>
            </div>
          </div>
          <StockItem class="" :icon="['WS1'].includes(whichCaseWH) ? 'barrier' : 'none'" :dot="['WS3','WS5'].includes(whichCaseWH) ? 'green' : ['WS4'].includes(whichCaseWH) ? 'grey' : 'none'">
            <div v-if="['WS3', 'WS4', 'WS5'].includes(whichCaseWH)" slot="quantity" class="flexbox" style="align-items: baseline;">
              <div class="bold semi-title">{{ hasField(data[0], 'warehouseStock.result.amount') ? data[0].warehouseStock.result.amount : '-' }}</div>
            </div>
            <div class="text-xs">{{ explanationWH }}</div>
          </StockItem>
        </div>
        <div class="position-relative">
          <StockItem class="line" :icon="['DT1'].includes(whichCase[0]) ? 'attention' : 'none'" :dot="['DT4','DT6'].includes(whichCase[0]) ? 'green' : ['DT2','DT3'].includes(whichCase[0]) ? 'grey' : 'none'">
            <template slot="caption">
              <div class="light">Lieferzeit ca.</div>
            </template>
            <div v-if="['DT2','DT3','DT4','DT6'].includes(whichCase[0])" slot="quantity" class="d-flex align-items-center left">
              <span class="semi-title pl-2 pr-1">{{ hasField(data[0], 'delivery.result.shippingTimeInWeeks') ?  data[0].delivery.result.shippingTimeInWeeks : '-' }} Wo.</span>
              <ph-arrow-right :size="16" />
              <span class="pl-2 semi-title">KW {{ hasField(data[0], 'delivery.result.calendarWeek') ? data[0].delivery.result.calendarWeek : '-' }}</span>
            </div>
            <div class="text-xs">{{ explanation[whichCase[0]] }}</div>
          </StockItem>
        </div>
      </div>
      <div class="more_stock" v-else-if="data.length > 1">
        <ph-warning-circle color="#555" :size="40" />
        <p>Modelle/Serien haben variierende Lieferzeiten. Details auf dem TAB Ausführungen alternativ zur Übersicht hier klicken</p>
        <a class="text-color-primary" @click="openModal">Lager/Lieferzeitenübersicht</a>
      </div>
    </div>
    <div class="vme-modal" v-if="showMore">
      <div class="vme-modal-content" :class="{ 'over-h': this.data.length > 6 }">
        <div class="vme-modal-content-body">
          <div class="d-flex justify-content-between">
            <h3 class="title">Bestand und Lieferzeit</h3>
            <a @click.prevent="closeModal" href="#">
              <ph-x :size="24" color="#555" />
            </a>
          </div>
          <table class="stock_table">
            <tr class="header">
              <th v-for="(h, i) in headerList" :key="i" :class="`th-${i+1}`">
                  {{ h }}
              </th>
            </tr>
            <tr v-for="(stock, idx) in data" :key="idx" :class="idx%2 ? 'bg_gray' : 'bg_white'">
              <td class="txt_wrap" :title="stock.warehouseStock.params.supplierArticleNumber">{{ stock.warehouseStock.params.supplierArticleNumber }}</td>
              <td class="txt_wrap" :title="additions[idx].HerstellerAusprägungsName">{{ additions[idx].HerstellerAusprägungsName }}</td>
              <td>{{ pad(stock.AusführungsNummerAlt,3) }}</td>
              <td class="txt_wrap" :title="additions[idx].HerstellerArtikelName">{{ additions[idx].HerstellerArtikelName }}</td>
              <td>{{ pad(stock.TeilNummerAlt,3) }}</td>
              <td class="txt_wrap">{{ modelName }}</td>
              <td>{{ stock.modelId }}</td>
              <td> {{ hasField(stock, 'merchantWarehouseStock.result.locations') ? (stock.merchantWarehouseStock.result.locations || []).reduce((acc, value) => acc + value.amount.sellable, 0) : '-' }} </td>
              <td>{{ hasField(stock, 'warehouseStock.result.amount') ? stock.warehouseStock.result.amount : '0' }}</td>
              <td>
                <StockItem class="line" :table="true" :icon="['DT1'].includes(whichCase[idx]) ? 'attention' : 'none'" :dot="['DT4','DT6'].includes(whichCase[idx]) ? 'green' : ['DT2','DT3'].includes(whichCase[idx]) ? 'grey' : 'none'">
                  <div v-if="['DT2','DT3','DT4','DT6'].includes(whichCase[idx])" slot="quantity" class="d-flex align-items-center">
                    <span class="semi-title pl-2 pr-1">{{ hasField(stock, 'delivery.result.shippingTimeInWeeks') ? stock.delivery.result.shippingTimeInWeeks : '-' }} Wo.</span>
                    <ph-arrow-right :size="16" />
                    <span class="pl-2 semi-title">KW {{ hasField(stock, 'delivery.result.calendarWeek') ? stock.delivery.result.calendarWeek : '-' }}</span>
                  </div>
                </StockItem>
              </td>
            </tr>
          </table>
          <div class="d-flex justify-content-between align-items-center mt-5">
            <button @click.prevent="closeModal" class="btn btn-primary px-4 text-sm h-fertig">Fertig</button>
            <div class="d-flex flex-column align-items-end">
              <p><b v-if="data[0].warehouseStock.result">Lieferant: </b>{{ hasField(data[0], 'warehouseStock.result.lastCheck') ? `${data[0].warehouseStock.result.lastCheck} Uhr` : '' }}</p>
              <p class="mb-0"><b>Mein Haus: </b>{{ germanStamp(data[0].lastCheck) || '-' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'
import StockItem from './StockItem.vue'
import loading from '@/components/common/Loading'
import { PhWarningCircle, PhX, PhArrowRight } from 'phosphor-vue'
import { decisionDeliveryWeek, decisionWarehouseStock, decisionMerchant } from '../../libs/decision'

export default {
  components: { PhWarningCircle, PhX, PhArrowRight, StockItem, loading },
  props: {
    additions: {
      type: Array,
      required: true,
      default: () => []
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    stockLoading: {
      type: Boolean,
      default: false
    },
    modelName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showMore: false,
      headerList: ['Artikel', 'Ausführungsname', 'Ausführ. Nr.', 'Teilename', 'Teilenr.', 'Modelname', 'Model. Nr.', 'Mein Haus Bestand', 'Bestand (Lieferant)', 'Lieferzeit ca']
    }
  },
  computed: {
    whichCase () {
      const arr = []
      for (let i = 0; i < this.data.length; i++) {
        arr.push(decisionDeliveryWeek(this.data[i]?.delivery?.result))
      }
      return arr
    },
    whichCaseWH () {
      return decisionWarehouseStock(this.data[0].warehouseStock?.result)
    },
    explanation () {
      return {
        DT1: 'Keine Lieferzeit vorhanden',
        DT2: 'Vertragliche Lfzt. - Verbindlich',
        DT3: 'Vertragliche Lfzt. - Unverbindlich',
        DT4: 'Unverbindlich',
        DT5: 'Keine Lieferzeit vorhanden',
        DT6: 'Unverbindlich',
        DT7: 'Zur Zeit vergriffen'
      }
    },
    explanationWH () {
      return {
        WS1: 'Lieferant noch nicht angebunden',
        WS2: 'Führt keinen Lagerbestand',
        WS3: this.data[0].warehouseStock?.result?.reavailability
          ? `ab ${this.convertDate(this.data[0].warehouseStock?.result?.reavailability)} verfügbar`
          : 'Zur Zeit vergriffen',
        WS4: 'keine Angabe',
        WS5: ' '
      }[this.whichCaseWH] || 'Lieferant noch nicht angebunden'
    },
    totalAmount () {
      return (this.data[0].merchantWarehouseStock?.result?.locations || []).reduce((acc, value) => acc + value.amount.sellable, 0) || ''
    },
    whichCaseM () {
      return decisionMerchant(this.data[0].merchantWarehouseStock)
    },
    explanationM () {
      return {
        M2: 'Lager noch nicht angeschlossen',
        M3: 'keine Angabe',
        M4: 'Lager noch nicht angeschlossen'
      }[this.whichCaseM] || ''
    }
  },
  methods: {
    germanStamp (value) {
      if (!value) value = new Date()
      return value.toLocaleDateString('de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }) + ' | ' + value.toLocaleTimeString('de', {
        hour: '2-digit',
        minute: '2-digit'
      }) + ' Uhr'
    },
    convertDate (dateStr) {
      const date = new Date(dateStr)
      return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    },
    closeModal () {
      this.showMore = false
      document.body.style.overflow = 'visible'
    },
    openModal () {
      this.showMore = true
      document.body.style.overflow = 'hidden'
    },
    hasField (object, key) {
      return _.has(object, key)
    }
  }
}
</script>
<style lang="scss" scoped>
.more_stock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 40px;
  font-size: 20px;
  p { text-align: center; }
  a { cursor: pointer; }
}

.stock_table {
  margin-top: 2rem;

  .header {
    background: #EE7F00;
  }

  th {
    text-align: center;
    color: #ffffff;
    padding: 8px 10px;
    white-space: nowrap;
    background: #EE7F00;
  }

  td {
    white-space: nowrap;
    padding: 15px 10px;
    color: #555555;
    text-align: center;
  }

  .th-1 {
    border-top-left-radius: 10px;
  }

  .th-10 {
    border-top-right-radius: 10px;
  }

  .bg_gray {
    background-color: #f0f0f0;
  }

  .bg_white {
    background-color: #ffffff;
  }
}

.h-fertig {
  height: 44px;
}

.over-h {
  max-height: 600px;
  overflow-y: scroll;
}

.txt_wrap {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.card.card-white {
  background-color: white;
}
</style>
