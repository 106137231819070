<template>
    <div class="popup p-4">
      <div class="form-group position-relative mb-4">
        <div class="form-control-icon">
          <ph-magnifying-glass weight="bold" :size="22" />
        </div>
        <input @keyup.enter="search" type="text" v-model="searchKeyw" class="form-control" placeholder="Lieferant, Modell, Warengruppe" />
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <ph-caret-left :size="32"  @click="prev" color="#c1b8b8" />
        <div class="d-flex main_cont">
          <transition name="slide">
            <div :style="`background-image: url(${imageArray[index]});`" class="image_cont"></div>
          </transition>
          <ph-x :size="24" @click="$emit('close')" class="mt-2 me-2" color="#f99a9a"/>
        </div>
        <ph-caret-right :size="32"  @click="next" color="#c1b8b8" />
      </div>
      <div class="d-flex justify-content-center mt-1">
        <span class="dot me-1" :class="{'dot_green': d-1 == index}" v-for="d in imageArray.length" :key="d"></span>
      </div>
    </div>
  </template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { PhMagnifyingGlass, PhCaretRight, PhCaretLeft, PhX } from 'phosphor-vue'
export default {
  name: 'ImageView',
  props: {
    imageArray: {
      type: Array,
      default: () => []
    }
  },
  components: { PhMagnifyingGlass, PhCaretRight, PhCaretLeft, PhX },
  data () {
    return {
      index: 0,
      searchKeyw: null
    }
  },
  computed: {
    ...mapGetters(['getCount'])
  },
  methods: {
    ...mapMutations(['SET_VERKAUF']),
    next () {
      this.index++
      if (this.index > this.imageArray.length - 1) {
        this.index = 0
      }
    },
    prev () {
      if (this.index === 0) {
        this.index = this.imageArray.length - 1
      } else {
        this.index--
      }
    },
    search () {
      if (this.searchKeyw.length > 2) {
        const einkaufRoutes = ['einkauf', 'buyerModels', 'buyerCatalogDetail']
        this.SET_VERKAUF(!einkaufRoutes.includes(this.$route.name))
        this.$router.push({ path: '/search', query: { supplier: this.searchKeyw, global: true, c: this.getCount } })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.popup {
  position: fixed;
  top:0;
  left: 0;
  z-index: 1001;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .65);
  background-blend-mode: darken;
}
.main_cont {
  width: 90%;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}
.image_cont {
  margin-top: 20px;
  height: 80vh;
  width: 100%;
  margin-right: -20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.popup svg{
    cursor: pointer;
}
.popup svg:hover {
  transform: scale(1.1);
}
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  &_green {
    background-color: #ee7f00;
  }
}
</style>
