<template>
    <div class="kalculations">
      <div v-if="kalculations">
        <div class="d-flex w-100 gray-bg">
          <div class="d-flex flex-column">
            <p class="kalk-header font-bold">
              Kalkulationstyp/name
            </p>
            <span class="w-100 kalk-border"></span>
            <p class="mt-3 mb-1 kalk-header font-bold">
              {{ kalculations.type}}
            </p>
          </div>
          <div v-for="(item, index) in values" :key="index" class="kalk-item">
            <div v-if="item" class="d-flex flex-column">
              <span class="mb-3 pe-4">{{ item.name ? item.name : '-' }}</span>
              <span class="w-100 kalk-border"></span>
              <span class="mt-3 font-bold pe-4">{{ item.stepValue ? `${item.stepValue} %` : '-' }}</span>
            </div>
          </div>
        </div>
        <div class="kalk-header font-bold mt-5 mb-2" style="margin-left:1.5em">Zu kalkulierender Wert</div>
      </div>
      <div style="width:600px" class="d-flex justify-content-center">
        <loading v-if="loading" class="mt-3" />
      </div>
      <div class="d-flex align-items-center justify-content-between w-100 gray-bg" v-if="kalculations">
        <span class="d-flex align-items-center me-4">
          <input
            type="number"
            v-model.trim="selectedKalk"
            @change="onKalk"
            style="min-width: 80px;width: 80px;"
            class="form-control bordered me-2"
          />
          %
        </span>
        <div class="d-flex">
          <span class="w-120 me-3">
          <button
            @click="downloadPdf"
            class="btn btn-primary"
            style="border-radius:10px;padding: 10px 15px"
            :disabled="!selectedKalk || error"
          >
            <ph-arrow-line-down :size="14" />
            {{ dLoading ? "Loading.." : "Download" }}
          </button>
        </span>
        <span class="w-120">
          <button
            @click="openPdfView"
            class="btn btn-secondary"
            style="border-radius:10px;padding: 10px 15px"
            :disabled="!selectedKalk || error"
          >
            <ph-arrow-line-up-right :size="14" />
            {{ qLoading ? "Loading.." : " Quick View" }}
          </button>
        </span>
        </div>
      </div>
      <div style="min-height: 2em;" class="mt-2">
        <span class="err" v-if="error">Werte unter 10% sind nicht möglich</span>
        <span class="err" style="float: right" v-if="err">Derzeit liegt zu diesem Wert keine herunterladbare Datei vor.</span>
      </div>
      <confirm-percent-range @closed="err=false" @update="createFile" v-if="err" :msg="disMsg" />
    </div>
</template>
<script>
import pdfHelper from '../../libs/pdf'
import loading from '../common/Loading.vue'
import ConfirmPercentRange from '../system/ConfirmPercentRange.vue'
import {
  PhArrowLineUpRight,
  PhArrowLineDown
} from 'phosphor-vue'
var fileDownload = require('js-file-download')

export default {
  props: ['supplierId', 'modelId'],
  components: {
    ConfirmPercentRange,
    PhArrowLineUpRight,
    PhArrowLineDown,
    loading
  },
  data () {
    return {
      qLoading: false,
      dLoading: false,
      loading: true,
      values: null,
      kalculations: null,
      selectedKalk: null,
      error: false,
      err: false,
      disMsg: {
        num: 5,
        text: 'Bitte rufen sie die Kalkulation  nach 5 Stunden wieder auf.'
      },
      p_confirmed: false
    }
  },
  methods: {
    async downloadPdf () {
      this.dLoading = true
      this.err = false
      try {
        const response = await this.$api.downloadPdf({ supplierId: this.supplierId, modelId: this.modelId, step: this.selectedKalk })
        if (response.status === 200) {
          for (let i = 0; i < response.data.length; i++) {
            const coversheetBuffer = Buffer.from(response.data[i].coversheet)
            const catalogueBuffer = await pdfHelper.downloadPdf(response.data[i].file)
            const buffer = await pdfHelper.mergeCoversheet(coversheetBuffer, catalogueBuffer)
            const filename = `${this.supplierId}_${this.modelId}_${this.selectedKalk}${i ? `_${i}` : ''}`
            fileDownload(buffer, `${filename}.pdf`)
          }
        }
      } catch (e) {
        console.log('[Download PDF Error]', e)
        this.err = true
      } finally {
        this.dLoading = false
      }
    },
    async openPdfView () {
      this.qLoading = true
      this.err = false
      try {
        const response = await this.$api.getPdfPrivew({ supplierId: this.supplierId, modelId: this.modelId, step: this.selectedKalk })
        if (response.status === 200) {
          if (window.open) {
            if (this.p_confirmed || confirm('Möchtest du mehrere Registerkarten öffnen?')) {
              this.p_confirmed = true
              for (let ix = 0; ix < response.data.file.length; ix++) {
                window.open(response.data.file[ix], '_blank')
              }
            }
          }
        }
      } catch (e) {
        this.err = true
      } finally {
        this.qLoading = false
      }
    },
    async createFile () {
      try {
        const res = await this.$api.createSPCZfile({ supplierId: this.supplierId, modelId: this.modelId, step: this.selectedKalk })
        if (res.status === 200) {
          this.err = false
          console.log('file creation started!')
        }
      } catch (e) {
        this.err = true
      } finally {
        this.qLoading = false
      }
    },
    onKalk (e) {
      const value = e.target.value
      if (value < 10) {
        this.error = true
      } else {
        this.error = false
      }
    }
  },
  async created () {
    const response = await this.$api.getkalculations({ supplierId: this.supplierId, modelId: this.modelId })
    this.kalculations = response.data.data
    this.values = Object.values(this.kalculations.calculation)
    this.loading = false
  }
}
</script>
<style>
.kalk-item {
  min-width: 100px;
}
.kalk-header{
  min-width: 220px;
}
.kalk-border{
  border-bottom: 3px solid #eee;
}
.gray-bg {
  padding: 15px 25px;
  background: #fafafa;
  border-radius: 10px;
}
.err {
  color:red;
  margin-left:1.5em
}
</style>
