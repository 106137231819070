<template>
  <div class="groups">
    <template v-if="tableView">
      <table-list
        :getList="getList"
        :tableView="tableView"
        :sortT="sortT"
        @sortT="onSortT"
      />
    </template>
    <template v-else v-for="(group, index) in getList">
      <div v-if="group.group !== '-'" :key="index" class="groups-item">
        <div class="groups-item-name mb-3" v-if="!fav">
          {{ group.group }}
        </div>
        <div
          class="groups-item-childs"
          :class="
            listingType === 'box' ? 'd-flex flex-wrap' : 'd-flex flex-column'
          "
        >
          <BuyerListItem
            v-for="(item, idx) in group.items"
            :isSearch="true"
            :listingType="listingType"
            :key="`${idx}${item._id}`"
            :item="item"
            @onCardClick="onCardClick"
            @onFilesClick="onFilesClick"
            @favChanged="setItems(index, idx)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BuyerListItem from '@/components/buyer/ListItem.vue'
import TableList from '@/components/common/TableList.vue'

export default {
  name: 'BuyerOverviewList',
  components: { TableList, BuyerListItem },
  props: {
    listingType: {
      required: true,
      type: String
    },
    getList: {
      required: true,
      type: Array,
      default: () => []
    },
    tableView: {
      type: Number,
      default: 0
    },
    sortT: {
      type: Object,
      default: null
    }
  },
  computed: {
    fav() {
      return this.$store.getters.getFavorite[
        this.$route.name === 'buyerModels' ? 'buyer' : 'seller'
      ].supplier
    }
  },
  methods: {
    onCardClick(payload) {
      this.$emit('onCardClick', payload)
    },
    onFilesClick(payload) {
      this.$emit('onFilesClick', payload)
    },
    setItems(i, ix) {
      this.$emit('setItms', i, ix)
    },
    onSortT(col, date = false) {
      this.$emit('sortT', col, date)
    }
  }
}
</script>
