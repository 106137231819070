<template>
  <div class="content">
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <loading />
    </div>
    <div v-if="!loading" class="d-flex w-100 position-relative">
      <perfect-scrollbar class="d-flex w-100" ref="ps">
        <div class="content-section w-100 px-4">
          <div class="row">
            <div :class="{ 'model-info-cont': showCata }">
              <page-title
                class="position-sticky top-0"
                style="z-index: 1000; width: 100%"
              />
              <div class="row align-items-stretch mt-3">
                <div class="col-7">
                  <model-info
                    :data="detail.summary"
                    :modelInfo="detail.modelInfo"
                    :isInterliving="isInterliving"
                    :resPerson="detail.responsiblePerson"
                  />
                </div>
                <div class="col-5">
                  <stock-info
                    :additions="detail.additions"
                    :model-name="detail.modelInfo.VMEModellName"
                    :stock-loading="stockLoading"
                    :data="stockInfo"
                  />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="tabs">
                    <div class="tab-header">
                      <ul>
                        <template v-for="item in items">
                          <li v-if="item.visible" :key="item.id">
                            <a
                              @click="changeTab(item.id)"
                              :class="{
                                active: item.id === selectedTab,
                                'pointer-none': item.disable
                              }"
                              href="#"
                              >{{ item.name }}</a
                            >
                          </li>
                        </template>
                      </ul>
                    </div>
                    <div class="tab-content">
                      <info-tab
                        :data="detail.modelInfo"
                        v-if="selectedTab === 0"
                      />
                      <contact-tab
                        :data="detail.contacts"
                        v-if="selectedTab === 5"
                      />
                      <aktionen-tab
                        :data="detail.modelInfo"
                        v-if="selectedTab === 4"
                      />
                      <tz-tab
                        :data="detail.modelInfo"
                        v-if="selectedTab === 3"
                      />
                      <ausfuhrung
                        :data="detail.additions"
                        :stock-info="stockInfo"
                        v-if="selectedTab === 2"
                        :key="ausfurgCount"
                      />
                      <kalkulations
                        :modelId="originalId"
                        :supplierId="supplierId"
                        v-if="selectedTab === 6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="{ 'pdf-cont': showCata }">
              <pdf-preview-side
                ref="pdfSide"
                @downloadPdf="downloadCatalog"
                :catalouges="documents"
                v-if="!loading && isVisibleConditionRole && showCata"
                :isInterliving="isInterliving"
                :isTrendhopper="isTrendhopper"
                :isRoomio="isRoomio"
                :isUVP="
                  $route.name === 'buyerCatalogDetail'
                    ? false
                    : isTrendhopper || isInterliving || isRoomio
                "
                :name="detail.summary.modelName"
              />
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { DateTime } from 'luxon'
import pdfHelper from '../libs/pdf'
import fileDownload from 'js-file-download'
import TzTab from '@/components/tabs/Tz.vue'
import { mapMutations, mapGetters } from 'vuex'
import infoTab from '@/components/tabs/ModelInfo.vue'
import ContactTab from '@/components/tabs/Contact.vue'
import Loading from '../components/common/Loading.vue'
import aktionenTab from '@/components/tabs/Aktionen.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import ModelInfo from '@/components/detail/ModelInfo.vue'
import Ausfuhrung from '@/components/tabs/Ausfuhrung.vue'
import StockInfo from '@/components/detail/StockInfo.vue'
import kalkulations from '@/components/tabs/kalkulations.vue'
import PdfPreviewSide from '../components/common/PdfPreviewSide.vue'

export default {
  components: {
    PageTitle,
    ModelInfo,
    StockInfo,
    Ausfuhrung,
    infoTab,
    aktionenTab,
    kalkulations,
    ContactTab,
    TzTab,
    Loading,
    PdfPreviewSide
  },
  name: 'CatalogDetail',
  methods: {
    ...mapMutations(['SET_BREADCRUMB', 'SET_VERKAUF_TAB', 'SET_EINKAUF_TAB']),
    changeTab(par) {
      this.selectedTab = par
      this.$route.name === 'buyerCatalogDetail'
        ? this.SET_EINKAUF_TAB(par)
        : this.SET_VERKAUF_TAB(par)
    },
    isUVP() {
      return this.$route.name === 'buyerCatalogDetail'
        ? false
        : this.isTrendhopper || this.isInterliving || this.isRoomio
    },
    async getModelDetail() {
      try {
        const response = await this.$api.getModelDetail(
          this.supplierId,
          this.originalId,
          JSON.stringify({ access: this.$store.getters.getFavoriteType })
        )
        this.detail = response.data.data
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        this.$emit('loading', false)
      }
    },
    async getModelCatalogs() {
      try {
        let type = null
        if (this.$route.name === 'sellerCatalogDetail') {
          type = 'seller'
        }
        if (this.$route.name === 'buyerCatalogDetail') {
          type = 'purchaser'
        }
        const data = {
          modelId: this.originalId,
          supplierId: this.supplierId
        }
        let response = null
        if (!this.isUVP()) {
          response = await this.$api.getModelCatalog(data, type)
        } else if (this.isInterliving) {
          response = await this.$api.getInterlivingModel(data)
        } else if (this.isTrendhopper) {
          response = await this.$api.getTrendhopperModel(data)
        } else if (this.isRoomio) {
          response = await this.$api.getRoomioModel(data)
        } else {
          response = await this.$api.getModelCatalog(data, type)
        }
        const dataCat = response.data.data
        const temp = []
        for (const item in dataCat) {
          let key =
            dataCat[item].validFrom +
            '+' +
            dataCat[item].validTill +
            '+' +
            dataCat[item].status +
            (dataCat[item]?.tz ? '+TZ' : '+')
          if (dataCat[item].zusatznr) {
            key = key + '+' + dataCat[item].zusatznr
          }
          const findIndex = temp.findIndex(tempItem => tempItem.label === key)
          if (findIndex === -1) {
            temp.push({
              label: key,
              ...dataCat[item],
              catalogues: []
            })
          }
        }
        temp.map(catalogue => {
          const parseCatalogue = catalogue.label.split('+')
          for (const item in dataCat) {
            if (
              dataCat[item].validFrom === parseCatalogue[0] &&
              dataCat[item].validTill === parseCatalogue[1] &&
              dataCat[item].status === parseCatalogue[2] &&
              dataCat[item].tz === !!parseCatalogue[3] &&
              dataCat[item].zusatznr === parseCatalogue[4]
            ) {
              catalogue.catalogues.push({
                ...dataCat[item],
                sortOrder: parseInt(dataCat[item].sortOrder.split('field')[1])
              })
            }
          }
        })
        temp.sort((a, b) => {
          return (
            DateTime.fromFormat(b.validFrom, 'dd.MM.yyyy').toJSDate() -
            DateTime.fromFormat(a.validFrom, 'dd.MM.yyyy').toJSDate()
          )
        })
        this.documents = temp
      } catch (e) {
        console.error(e)
      }
    },
    async getStockInfo() {
      this.stockLoading = true
      const data = {
        modelId: this.originalId,
        supplierId: this.supplierId
      }
      const response = await this.$api.getStockInfo(data)
      this.stockInfo = response.data.data
      for (let i = 0; i < this.stockInfo.length; i++) {
        this.stockInfo[i].lastCheck = new Date()
      }
      this.ausfurgCount++
      this.stockLoading = false
    },
    pad(num) {
      num = num.toString()
      while (num.length < 3) num = '0' + num
      return num
    },
    parseCatalogVal(par) {
      const [validFrom, validTill, stepValue, status, TZ] = par.split('+')
      const TZVal = TZ === '_TZ'
      return this.catalogues.filter(catalogue => {
        return (
          catalogue.validFrom === validFrom &&
          catalogue.validTill === validTill &&
          catalogue.status === status &&
          catalogue.stepValue === stepValue &&
          catalogue.tz === TZVal
        )
      })
    },
    async downloadCatalog(par) {
      try {
        let response = null
        if (this.isUVP()) {
          let uvpURL
          if (this.isTrendhopper) {
            uvpURL = 'trendhopper-uvps'
          } else if (this.isInterliving) {
            uvpURL = 'interliving-uvps'
          } else if (this.isRoomio) {
            uvpURL = 'roomio-uvps'
          } else {
            throw new Error(
              'Invalid state: none of the expected conditions are true.'
            )
          }

          response = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/${uvpURL}/supplier/${par.supplierId}/model/${par.modelId}/download`,
            {
              headers: { Authorization: 'Bearer ' + this.getAccessToken }
            }
          )
          // response = await axios.get(
          //   `${process.env.VUE_APP_BASE_URL}/${
          //     this.isTrendhopper ? 'trendhopper' : 'interliving'
          //   }-uvps/supplier/${par.supplierId}/model/${par.modelId}/download`,
          //   {
          //     headers: { Authorization: 'Bearer ' + this.getAccessToken }
          //   }
          // )
          const pdfbuffer = await pdfHelper.downloadPdf(response.data.file)
          fileDownload(pdfbuffer, par.name)
        } else {
          let data = {
            tz: par.tz,
            step: par.stepValue,
            status: par.status,
            validFrom: par.validFrom,
            validTill: par.validTill
          }
          if (par.zusatznr) data = { ...data, zusatznr: par.zusatznr }
          response = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/catalogue/supplier/${this.supplierId}/model/${this.originalId}/download`,
            data,
            { headers: { Authorization: 'Bearer ' + this.getAccessToken } }
          )

          let filename = `${this.detail.summary.modelName}_${
            par.tz ? 'TZ' : ''
          }_${par.validFrom}_${par.validTill}_${par.status}_${par.supplierId}_${
            par.originalId
          }_${par.stepName}`
          if (par.zusatznr) {
            filename += `_v${par.zusatznr}`
          }
          if (par.zusatz) {
            filename += `_${par.zusatz}`
          }
          const coversheetBuffer = Buffer.from(response.data.coversheet)
          const catalogueBuffer = await pdfHelper.downloadPdf(
            response.data.file
          )
          const buffer = await pdfHelper.mergeCoversheet(
            coversheetBuffer,
            catalogueBuffer
          )
          fileDownload(buffer, `${filename}.pdf`)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.$refs.pdfSide.deleteLoading(par.index)
      }
    }
  },
  data() {
    return {
      loading: true,
      detail: {},
      supplierId: null,
      modelId: null,
      originalId: null,
      isInterliving: false,
      isTrendhopper: false,
      isRoomio: false,
      selectedTab: 0,
      stockInfo: [],
      ausfurgCount: 0,
      stockLoading: false,
      catalogues: [],
      documents: [],
      items: [
        {
          visible: true,
          id: 0,
          name: 'Modellinfo'
        },
        {
          visible: true,
          disable: false,
          id: 2,
          name: 'Ausführung'
        },
        {
          visible: true,
          disable: false,
          id: 3,
          name: 'Teuerungszuschläge'
        },
        {
          visible: true,
          disable: false,
          id: 4,
          name: 'Aktionen'
        },
        {
          visible: true,
          id: 5,
          name: 'Kontakt'
        },
        {
          visible: true,
          disable: false,
          id: 6,
          name: 'Kalkulationen'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getUserRole',
      'getAccessToken',
      'getUserOneRole',
      'getVerkaufTab',
      'getEinkaufTab'
    ]),
    isVisibleConditionRole() {
      const roles = this.getUserRole
      if (this.$route.name === 'buyerCatalogDetail') {
        const role = roles.findIndex(
          item => item === 'isMerchantAdminUser' || item === 'isPurchaserUser'
        )
        if (role !== -1) {
          return true
        }
      }
      return true
    },
    showCata() {
      return this.documents.length > 0 || this.supplierId[0] === '9'
    }
  },
  async created() {
    this.$emit('loading', true)
    const isBuyer = this.getUserOneRole('isPurchaserUser')
    const isSeller = this.getUserOneRole('isSellerUser')
    if (this.$route.name === 'buyerCatalogDetail' && isBuyer === -1) {
      return this.$router.push('/access-denied')
    }
    if (this.$route.name === 'sellerCatalogDetail' && isSeller === -1) {
      return this.$router.push('/access-denied')
    }
    this.selectedTab =
      this.$route.name === 'sellerCatalogDetail'
        ? this.getVerkaufTab
        : this.getEinkaufTab
    this.supplierId = this.$route.query.supplierId
    this.modelId = parseInt(this.$route.params.id)
    this.originalId = this.pad(this.modelId)

    await this.getModelDetail()

    const productHandelsmarken = this?.detail?.modelInfo?.Handelsmarken

    this.isInterliving =
      this.supplierId.charAt(0) === '9' &&
      this.supplierId !== '999344' &&
      this.$route.name === 'sellerCatalogDetail'
    this.isTrendhopper =
      this.detail.modelInfo.Exklusivität === 'Trendhopper' ||
      this.detail.modelInfo.Modelmerker === 'TH' ||
      productHandelsmarken === 'TRENDHOPPER' ||
      this.detail.modelInfo.Sortiment === 'TH'

    this.isRoomio = productHandelsmarken
      ? productHandelsmarken.toLowerCase() === 'roomio'
      : false

    if (this.$route.name !== 'buyerCatalogDetail') {
      for (const item of this.items) {
        if (
          item.name === 'Teuerungszuschläge' ||
          item.name === 'Kalkulationen' ||
          item.name === 'Aktionen'
        ) {
          item.visible = false
        }
      }
    }
    this.getModelCatalogs()
    this.getStockInfo()
    const data = [
      {
        title: 'Modell'
      },
      {
        title:
          this.$route.name !== 'sellerCatalogDetail'
            ? ' / EINKAUF'
            : ' / VERKAUF'
      }
    ]
    this.SET_BREADCRUMB(data)
  }
}
</script>

<style>
.model-info-cont {
  flex: 0 0 auto;
  width: calc(100% - 300px);
}
.pdf-cont {
  flex: 0 0 auto;
  width: 300px;
}
@media only screen and (max-width: 1024px) {
  .model-info-cont {
    width: calc(100% - 50px);
  }
  .pdf-cont {
    width: 50px;
  }
}
</style>
