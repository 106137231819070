var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card h-100 card-white"},[_c('div',{staticClass:"card-body m-info",on:{"mouseleave":function($event){_vm.showFav = false},"mouseover":function($event){_vm.showFav = true}}},[_c('div',{staticClass:"m-info-left"},[_c('div',{staticClass:"model-info-header d-flex w-100 align-items-start"},[_c('div',{staticClass:"model-info-image me-4 img_hover",on:{"click":function($event){_vm.showImg = true}}},[_c('img',{attrs:{"src":_vm.data.modelImage,"alt":"Model name"},on:{"error":_vm.replaceByDefaultImg}}),_c('i',{attrs:{"aria-hidden":"true"}},[_c('ph-arrows-out',{attrs:{"size":32,"color":"#f99a9a"}})],1)]),_c('div',{staticClass:"flex-grow-1 d-flex flex-column justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"model-number"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-3"},[_c('CopyButton',{attrs:{"text":_vm.getVAN(_vm.data.supplierId, _vm.data.modelId),"text-class":'desc text-xs text-color-primary ms-1 font-500'}})],1),_c('span',{staticClass:"desc text-xs text-color-primary ms-1 font-500"},[_vm._v(_vm._s(`(${_vm.pad(_vm.data.supplierId, 6)}-${_vm.pad(_vm.data.modelId, 4)})`))])]),_c('div',{staticClass:"cursor-pointer",staticStyle:{"width":"30px"}},[(_vm.showFav || _vm.isFav)?_c('star-component',{ref:"star",attrs:{"isFavorite":_vm.isFav,"listingType":"table"},on:{"onFav":_vm.onFav}}):_vm._e()],1)]),_c('span',{staticClass:"model-title text-sm font-500"},[_vm._v("Modell: "+_vm._s(_vm.data.modelName))])])]),_c('div',{staticClass:"model-info-footer d-flex m-info-b"},[_c('div',{staticClass:"item"},[_vm._m(0),_c('div',{staticClass:"item-right"},[_c('span',{staticClass:"desc"},[_vm._v("Warengruppe")]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.goodsGroup ? _vm.data.goodsGroup.split(' ')[0] : ''))])])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push({
              path: `/${
                _vm.$route.name === 'sellerCatalogDetail' ? 'seller' : 'buyer'
              }/models?seller=${_vm.data.supplierName}&id=${_vm.data.supplierId}`
            })}}},[_vm._m(1),_c('div',{staticClass:"item-right"},[_c('span',{staticClass:"title text-sm"},[_vm._v(_vm._s(_vm.data.supplierName))])])])]),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center mt-3"},[(_vm.resPerson.fullName)?_c('div',[_c('p',{staticClass:"mb-0 text-xs"},[_vm._v(_vm._s(`${_vm.resPerson.fullName}`))])]):_c('div',[_c('p',{staticClass:"text-center mb-0 text-xs"},[_vm._v(" Kein Ansprechpartner gepflegt. ")])]),(_vm.resPerson.fullName)?_c('div',{staticClass:"person-info-title"},[_c('p',{staticClass:"mb-0"},[_vm._v("Einkauf")])]):_vm._e()])]),_c('div',{staticClass:"m-info-right"},[_c('div',{staticClass:"model-info-footer m-info-r d-flex w-100 pl-4"},[(_vm.showTzText(_vm.modelInfo) && !_vm.isInterliving)?_c('div',{staticClass:"item-right"},[_c('span',{staticClass:"title text-sm text-color-primary"},[_vm._v(_vm._s(_vm.getTzText(_vm.modelInfo)))])]):_vm._e(),(_vm.data.status)?_c('div',{staticClass:"item-right"},[_c('span',{staticClass:"desc"},[_vm._v("Status:")]),_c('span',{staticClass:"title text-sm",class:`text-color-${
              _vm.data.status === 'inaktiv' ? 'danger' : 'success'
            }`},[_vm._v(_vm._s(_vm.data.status))])]):_vm._e(),(_vm.modelInfo.PreisbindungVon)?_c('div',{staticClass:"item-right"},[_c('span',{staticClass:"desc"},[_vm._v("Gültig ab")]),_c('span',{staticClass:"title text-sm"},[_vm._v(_vm._s(_vm.modelInfo.PreisbindungVon))])]):_vm._e(),(_vm.modelInfo.PreisbindungBis)?_c('div',{staticClass:"item-right"},[_c('span',{staticClass:"desc"},[_vm._v("Gültig bis")]),_c('span',{staticClass:"title text-sm"},[_vm._v(_vm._s(_vm.modelInfo.PreisbindungBis))])]):_vm._e(),_vm._m(2)]),_c('div',{staticClass:"btn-currency",on:{"click":_vm.changeRoute}},[_c('ph-currency-eur',{attrs:{"weight":"bold","size":24,"color":"#ee7f00"}})],1)]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showImg && _vm.data.modelImages.length > 0)?_c('ImageViewer',{attrs:{"imageArray":_vm.data.modelImages},on:{"close":function($event){_vm.showImg = false}}}):_vm._e()],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-left"},[_c('img',{attrs:{"src":"/imgs/dummy_model_icon.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-left"},[_c('img',{attrs:{"src":"/imgs/dummy_logo.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-right"},[_c('span',{staticClass:"desc"},[_vm._v("Zuletzt beabeitet")]),_c('span',{staticClass:"title text-sm"},[_vm._v("-")])])
}]

export { render, staticRenderFns }