<template>
  <div class="row justify-content-between w-100">
    <div class="col-7 ">
      <div class="w-85">
        <div class="d-flex justify-content-between mb-3" v-if="data.isExclusive">
          <div class="font-bold text-color-primary">
            Exklusiv
          </div>
          <div class="d-flex w-50">
            <span class="ms-1 text-left d-block text-color-primary">{{data.Exklusivität}}</span>
          </div>
        </div>
        <p class="font-bold text-color-primary mb-3" v-if="data.Handelsmarken">
          {{data.Handelsmarken}}
        </p>
        <p class="text-uppercase">
          <strong>Bezeichnung</strong>
        </p>
        <div class="item d-flex justify-content-between mb-1">
          <strong class="text-sm">VME Modellname:</strong>
          <span class="ms-1 text-left d-block w-50 font-400">{{data.VMEModellName}}</span>
        </div>
        <div v-if="routeName !== 'sellerCatalogDetail'" class="item d-flex justify-content-between mb-1">
          <strong class="text-sm">Hersteller-Modellname:</strong>
          <span class="ms-1 text-left d-block w-50 font-400">{{data.HerstellerModellName}}</span>
        </div>
        <div class="item d-flex justify-content-between mb-1">
          <strong class="text-sm">Text zum Modell:</strong>
          <span class="ms-1 text-left d-block w-50 font-400">{{data.Modelltext}}</span>
        </div>
      </div>
      <div class="w-85">
        <p    class="text-uppercase mt-5 "><strong>Beschreibung</strong></p>
       <div v-if="routeName !== 'sellerCatalogDetail'"  class="item mb-1 d-flex align-items-start justify-content-between">
         <strong class="text-sm">EKText:</strong>
         <span class="text-left d-block w-50 font-400">{{data.EKText || 'Es liegen keine Informationen vor.'}}</span>
       </div>
       <div  class="item mb-1 d-flex align-items-start justify-content-between">
         <strong class="text-sm">Modellbeschreibung:</strong>
         <span class="text-left d-block w-50 font-400">{{data.Modellbeschreibung || 'Es liegt keine Modellbeschreibung vor.' }}</span>
       </div>
      </div>
    </div>
    <div v-if="routeName !== 'buyerCatalogDetail'" class="col-4 d-none">
      <p><strong>Fußvarianten:</strong></p>
      <p class="text-sm">
          Metallfu8 Schwarz matt, Nr. 324 <br />
      Metallfu8 Chrom glénzend, Nr. 323 <br />
      Metallfu8 Chrom matt, Nr. 325 <br />
      Holz-Metallfu Eiche gedit, Nr. 326 - gegen Aufpreis
      </p>
    </div>
    <div class="col-5 buyer-condition">
      <p><strong class="text-uppercase">Konditionen:</strong></p>
      <span v-if="isVisibleConditionRole">
        <p class="mb-0"></p>
        <p v-if="data.erstelltAm" class="text-sm"><strong>Aktualisiert am</strong><span class="ms-1">{{data.erstelltAm}}</span></p>
        <p v-if="data.PreisbindungBis" class="text-sm"><strong>Preisbindung bis</strong><span class="ms-1">{{data.PreisbindungBis}}</span></p>
        <p v-if="data.PreisbindungVon" class="text-sm"><strong>Preisbindung von</strong><span class="ms-1">{{data.PreisbindungVon}}</span></p>
        <p v-if="data.MindestbestellmengeText" class="text-sm"><strong>Mindestbestellmenge text</strong><span class="ms-1">{{data.MindestbestellmengeText}}</span></p>
        <p v-if="data.Skontotage" class="text-sm"><span>Skontotage</span><strong class="ms-1">Skontotage</strong></p>
        <p v-if="data.Rabatt1" class="text-sm"><strong>Handelsrabatt</strong> <span>{{data.Rabatt1}}%</span></p>
        <p v-if="data.Rabatt2" class="text-sm"><strong>Sofortrabatt</strong> <span>{{data.Rabatt2}}%</span></p>
        <p v-if="data.Rabatt3" class="text-sm"><strong>Sonderrabatt 1</strong><span>{{data.Rabatt3}}%</span></p>
        <p v-if="data.Rabatt4" class="text-sm"><strong>Sonderrabatt 2</strong><span>{{data.Rabatt4}}%</span></p>
        <p v-if="data.SkontoTage" class="text-sm"><strong>SkontoTage</strong><span>{{data.SkontoTage}}</span></p>
        <p v-if="data.Skonto1" class="text-sm"><strong>Skonto 1</strong><span>{{data.Skonto1}}%</span></p>
        <p v-if="data.Skonto2" class="text-sm"><strong>Skonto 2</strong><span>{{data.Skonto2}}%</span></p>
      </span>
      <p v-if="data.Lieferzeit" class="text-sm"><strong >Lieferzeit</strong><span>{{data.Lieferzeit}} AT</span></p>
      <p v-if="isVisibleConditionRole && data.KarenzTage" class="text-sm"><strong>Karenz</strong><span>{{data.KarenzTage}} AT</span></p>
      <span v-if="isVisibleConditionRole">
        <p class="mb-0"></p>
        <p v-if="data.MindestbestellmengeText" class="text-sm"><strong>Mindestbestellmenge</strong><span>{{data.MindestbestellmengeText}}</span></p>
        <p v-if="data.MindestbestellmengeArtikel" class="text-sm"><strong>Mindestbestellmenge Artikel</strong><span>{{data.MindestbestellmengeArtikel}}</span></p>
        <p v-if="data.Mindestbestellwert" class="text-sm"><strong>Mindestbestellwert</strong><span>{{data.Mindestbestellwert}} €</span></p>
        <p v-if="data.MindestbestellwertText" class="text-sm">
          <strong style="min-width: fit-content;">Mindestbestellwert Text</strong>
          <span style="margin-left: 5em;text-align: justify;">{{data.MindestbestellwertText}}</span>
        </p>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['getUserRole']),
    isVisibleConditionRole () {
      const roles = this.getUserRole
      if (this.routeName === 'buyerCatalogDetail') {
        const role = roles.findIndex(item => item === 'isMerchantAdminUser' || item === 'isPurchaserUser')
        if (role !== -1) {
          return true
        }
      }
      return false
    }
  },
  data () {
    return {
      routeName: null
    }
  },
  created () {
    this.routeName = this.$route.name
  }
}
</script>

<style>
.font-400 {
  font-weight: 400;
}
</style>
