<template>
  <div class="content m-0" :class="{'overflow-hidden': loading}">
    <div v-if="loading" class="container-fluid">
      <div class="row h-100">
        <div class="col-12 h-100">
          <div class="page-wrapper justify-content-center align-items-center h-100">
            <loading />
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar @ps-scroll-y="onScroll" class="w-100" ref="ps" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12" style="height:80px">
            <page-title />
          </div>
          <div v-show="!isSearch" class="col-12">
            <div class="page-wrapper">
              <page-wrapper-header
                :sorting='sorting'
                :isSupplier="true"
                :listType="listingType"
                :titleItems="title"
                :fav="isFav"
                @favType="changeFavType"
                @fav="changeFav"
                @sorting="changeSort"
                @changeListingType="changeListingType"
              />
              <div class="page-wrapper-body">
                <list
                  :type="'seller'"
                  @click="clickItem"
                  :listingType="listingType"
                  :getList="list"
                />
                <div v-if="notMoreData && !loadingMore" class="row">
                  <div class="col-12">
                    <h3 class="justify-content-center d-flex w-100 mb-5 text-Keine">Keine weiteren Daten</h3>
                  </div>
                </div>
                <div v-if="loadingMore" class="row">
                  <div class="col-12">
                    <loading :size="'sm'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <sidebar-filter :path='path' />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import List from '@/components/common/List.vue'
import Loading from '../components/common/Loading.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import SidebarFilter from '../components/common/SidebarFilter.vue'
import PageWrapperHeader from '../components/common/PageWrapperHeader.vue'
export default {
  components: { PageTitle, List, PageWrapperHeader, SidebarFilter, Loading },
  name: 'SellerList',
  data () {
    return {
      page: 1,
      list: [],
      title: [],
      filter: { modelStatus: 'active' },
      sort: 'asc',
      loading: true,
      sorting: 'AZ',
      paginator: {},
      path: 'seller',
      isSearch: false,
      notMoreData: false,
      loadingMore: false,
      listingType: 'box',
      type: 'supplierName',
      groupId: false,
      isFav: false
    }
  },
  computed: {
    ...mapGetters(['getUserOneRole', 'getListingType', 'getFavorite']),
    getList () {
      return this.list
    },
    suppCurrPos () {
      return this.$store.state.currPgState.supplier
    }
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB', 'SET_LISTING_TYPE', 'SET_FAVORITE', 'SET_FAVORITE_TYPE', 'SET_CURRENT_PAGE_STATE', 'SET_SUPPLIER_CURRENT_STATE']),
    onScroll (el) {
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop + 2) >= el.srcElement.scrollHeight) {
        this.moreData()
      }
    },
    moreData () {
      if (this.page === this.paginator.totalPages) {
        this.notMoreData = true
        return
      }
      if (!this.loadingMore) {
        this.page++
        this.getSellerList(true)
      }
    },
    clickItem (par) {
      this.$store.state.prevPg = this.$route.name
      this.SET_CURRENT_PAGE_STATE({ type: 'supplier', pos: this.$refs.ps.$el.scrollTop })
      this.SET_SUPPLIER_CURRENT_STATE([this.list, this.title, this.sorting])
      this.$store.commit('SET_CURRENT_PAGE_STATE', { type: 'model', pos: 0 })
      const query = { seller: par.fullName, id: par.supplierId }
      if (this.$route.query.modelStatus) {
        query.modelStatus = this.$route.query.modelStatus
      }
      this.$router
        .push({
          path: '/seller/models',
          query
        })
        .catch(() => {})
    },
    changeFavType (type) {
      this.SET_FAVORITE_TYPE(type)
      this.loading = true
      this.getSellerList()
    },
    changeListingType (par) {
      this.SET_LISTING_TYPE(par === 'box' ? 0 : 1)
      this.listingType = par
    },
    changeSort (par) {
      if (par === 'AZ') {
        this.sort = 'asc'
      }
      if (par === 'ZA') {
        this.sort = 'desc'
      }
      this.sorting = par
      this.loading = true
      this.page = 1
      if (par === 'AZ' || par === 'ZA') {
        this.type = 'supplierName'
        this.groupId = false
        this.getSellerList()
      }
      if (par === '09') {
        this.sort = 'asc'
        this.type = 'supplierId'
        this.groupId = true
        this.getSellerList()
      }
      if (par === '90') {
        this.sort = 'desc'
        this.type = 'supplierId'
        this.groupId = true
        this.getSellerList()
      }
    },
    async changeFav (show) {
      this.isFav = show
      this.SET_FAVORITE({ route: 'seller', type: 'supplier', val: show })
      this.page = 1
      this.getSellerList()
    },
    async getSellerList (more = false) {
      try {
        if (more) {
          this.loadingMore = true
        }
        if (!more) {
          this.list = []
        }

        let query = { page: this.page, sortType: this.sort, sortField: this.type }
        if (this.filter) {
          if (this.$route.query.modelStatus) {
            this.filter.modelStatus = this.$route.query.modelStatus
          }
          this.filter = { ...this.filter, access: this.$store.getters.getFavoriteType }
          query = {
            ...query, filter: JSON.stringify(this.filter)
          }
        }
        const response = this.isFav ? await this.$api.getFavorites('suppliers', query) : await this.$api.getSellerList(query)
        this.paginator = response.data.data.paginator
        const items = response.data.data.items
        this.title = [
          {
            title: 'Lieferanten',
            count: this.isFav ? items.length : response.data.data.statistics.totalSupplierCount
          },
          {
            title: this.isFav ? '' : 'Kataloge',
            count: this.isFav ? '' : response.data.data.statistics.totalCatalogueCount
          }
        ]
        items.map((item) => {
          const group = this.groupId ? item.supplierId.charAt(0) : item.fullName.toUpperCase().charAt(0)
          const findIndex = this.list.findIndex((item) => item.group === group)

          if (findIndex === -1) {
            this.list.push({
              group,
              items: []
            })
          }
          const findIndexIshave = this.list.findIndex(
            (item) => item.group === group
          )
          if (findIndexIshave !== -1) {
            this.list[findIndexIshave].items.push({ ...item })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        if (more) {
          this.loadingMore = false
        }
        this.loading = false
      }
    }
  },
  created () {
    if (this.$route.query.supplierId) {
      this.filter = { supplierId: this.$route.query.supplierId }
    }
    const isSeller = this.getUserOneRole('isSellerUser')
    if (isSeller === -1) {
      return this.$router.push('/access-denied')
    }
    const data = [
      {
        title: 'LIeferant'
      },
      {
        title: ' / VERKAUF'
      }
    ]
    if (this.$route.query.search) {
      this.isSearch = true
    }
    this.listingType = this.getListingType ? 'list' : 'box'
    this.isFav = this.getFavorite?.seller?.supplier
    this.SET_BREADCRUMB(data)
    if (this.suppCurrPos) {
      this.loading = false
      const state = Object.values(this.$store.state.supplierCurrState)
      this.list = state[0]
      this.title = state[1]
      this.sorting = state[2]
      return
    }
    this.getSellerList()
  },
  mounted () {
    if (this.suppCurrPos) this.$refs.ps.$el.scrollTop = this.suppCurrPos
  }
}
</script>
