<template>
  <div class="stock d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center mt-1 p-2" :class="table ? 'gap-2' : 'white_info'">
      <img v-if="icon === 'barrier'" src="@/assets/icons/Baustelle.svg" width="24" height="24">
      <img v-else-if="icon === 'attention'" src="@/assets/icons/Warning.svg" width="24" height="24">
      <div v-else>
        <slot name="quantity" />
      </div>
      <div class="right font-semi-small d-flex align-items-center">
        <slot />
        <svg v-if="dot !== 'none'" viewBox="0 0 10 10" width="12" height="12" class="ml-2">
          <circle cx="5" cy="5" r="5" :fill="dot === 'green' ? '#0ce846' : '#C5C5C5'" />
        </svg>
      </div>
    </div>
    <div class="pt-1 light" v-if="!table">
      <slot name="caption">Lagerbestand (Stück)</slot>
    </div>
  </div>
</template>

<script>
export default
{
  name: 'StockItem',
  props: {
    icon: {
      type: String,
      default: 'none',
      validator: (value) => ['none', 'barrier', 'attention'].includes(value)
    },
    dot: {
      type: String,
      default: 'none',
      validator: (value) => ['green', 'grey', 'none'].includes(value)
    },
    table: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style>
.grey_info {
  background: #f0f0f0;
}

.white_info {
  background: white;
}

.grey_info, .white_info {
  margin-top: 20px;
}

.stock {
  .title {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .semi-title {
    font-size: 20px;
    font-weight: 700;
    color: #555;
  }

  .light {
    color: rgb(144, 144, 144);
    font-size: 11px;
  }

  .left {
    gap: 4px;
  }

  .right {
    gap: 8px;
  }
}
</style>
